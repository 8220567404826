<template>
  <v-container class="full-height pa-4" fluid>
    <div class="d-flex align-center justify-center full-width full-height">
      <v-progress-circular size="96" width="8" color="primary" indeterminate />
    </div>
  </v-container>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import offerPartService from '@/api/offer-part-service';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';
import { mapGetters } from 'vuex';

export default {
  name: 'OfferPart',

  mixins: [crudMixin],

  computed: {
    ...mapGetters(['currentUser']),
  },

  async created() {
    if (!this.currentUser) {
      await this.$router.push({ name: 'login' });
      eventBus.$emit(OPEN_SNACKBAR, {
        text: this.$t('login_and_scan_qr_code_again'),
        timeout: 10000,
      });
      return;
    }

    const offerPartRes = await this.crudMixin_getById(
      offerPartService.getById,
      'part',
      this.$route.params.id,
      { all_relations: 1 },
      false,
    );
    await this.$router.push({
      name: 'offers',
      query: { search: offerPartRes?.data?.offer?.offer_no, offerPartId: offerPartRes?.data?.id },
    });
  },
};
</script>

<style scoped></style>
