import http from './http';
import i18n from '../i18n/i18n-config';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';

const endpoint = 'api/offer-parts';

const getOfferPartRequestBody = part => {
  const body = JSON.parse(JSON.stringify(part));
  for (let i = 0; i < body.materials?.length; i++) {
    if (typeof body.materials[i].id === 'string') {
      // key generated by uuidv4 to be used as :key in the table while id is absent
      delete body.materials[i].id;
    }
  }
  if (body.inventory_item?.type === 'bought') {
    return {
      title: body.title,
      offer_id: body.offer_id,
      inventory_item_id: body.inventory_item.id,
      quantity_in_warehouse: body.quantity_in_warehouse,
      quantity_issued: body.quantity_issued,
      warehouse_location_id: body.warehouse_location_id,
      processes: [],
    };
  }

  return body;
};

const offerPartService = {
  model: 'offerPart',

  getAll: () => http.get(`${endpoint}`),

  getById: (id, params) => http.get(`${endpoint}/${id}`, { params }),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: offerPart => http.post(`${endpoint}`, getOfferPartRequestBody(offerPart)),

  update: offerPart => http.put(`${endpoint}/${offerPart.id}`, getOfferPartRequestBody(offerPart)),

  delete: offerPart => http.delete(`${endpoint}/${offerPart.id}`),

  updateProcess: process =>
    http.patch(`api/offer-part-processes/${process.id}`, process).catch(err => {
      if (
        err?.response?.status === 422 &&
        err.response.data?.errors?.status?.includes(
          'validation.can_complete_if_previous_processes_already_started',
        )
      ) {
        eventBus.$emit(OPEN_SNACKBAR, {
          text: i18n.t('can_complete_if_previous_processes_already_started'),
          timeout: 10000,
        });
      }
      return Promise.reject(err);
    }),
};

export default offerPartService;

export const OFFER_PART_DELIVERED_BY_TYPES = [
  {
    text: process.env.VUE_APP_CUSTOM_APP_TITLE || i18n.t('delivered_by_types.us'),
    value: 'us',
    color: 'grey lighten-4',
    hexColor: '#F5F5F5',
  },
  {
    text: i18n.t('delivered_by_types.supplier'),
    value: 'supplier',
    color: 'blue lighten-4',
    hexColor: '#BBDEFB',
  },
  {
    text: i18n.t('delivered_by_types.client'),
    value: 'client',
    color: 'green accent-1',
    hexColor: '#B9F6CA',
  },
  {
    text: i18n.t('delivered_by_types.other'),
    value: 'other',
    color: 'grey lighten-2',
    hexColor: '#E0E0E0',
  },
];

export const OFFER_PART_DOCUMENT_TYPES = [
  { value: 'material_certificate', text: i18n.t('offer_part_document_types.material_certificate') },
  {
    value: 'declaration_of_conformity',
    text: i18n.t('offer_part_document_types.declaration_of_conformity'),
  },
  { value: 'invoice', text: i18n.t('offer_part_document_types.invoice') },
  { value: 'bill_of_lading', text: i18n.t('offer_part_document_types.bill_of_lading') },
  { value: 'other', text: i18n.t('offer_part_document_types.other') },
];

export const getOfferPartDocumentTypesMap = () => {
  const map = {};
  OFFER_PART_DOCUMENT_TYPES.forEach(type => {
    map[type.value] = { ...type };
  });
  return map;
};
